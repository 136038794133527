<template>
  <v-container fluid grid-list-xs pa-0>
    <v-layout row>
      <v-flex sm12 md8 lg8>
        <v-card class="elevation-12">
          <v-toolbar dense dark color="secondary">
            <v-toolbar-title>Create new Session</v-toolbar-title>
          </v-toolbar>
          <v-progress-linear style="position: absolute" v-show="loading" :indeterminate="true" class="ma-0"></v-progress-linear>
          <v-card-text>
            <v-layout row wrap v-if="isAdminOrBilling">
              <v-flex xs12 sm6>
                <select-user v-model="userId" @change="changedUser()"></select-user>
              </v-flex>
              <v-flex xs12 sm6>
                <v-select box hid :disabled="loading" :items="clients" v-model="clientId" label="Client" prepend-icon="fa-user" item-text="clientName" item-value="clientId" @input="clientSelected">
                  <template slot="item" slot-scope="{ item }">
                    <v-list-tile-avatar>
                      <img :style="!item.active ? 'opacity: 0.5' : ''" :src="`images/${item.gender ? item.gender.toLowerCase() : 'nogender'}.png`" />
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title :class="{ 'grey--text text--lighten-1': !item.active }">{{ item.clientName }}</v-list-tile-title>
                      <v-list-tile-sub-title :class="{ 'grey--text text--lighten-1': !item.active }"
                        >{{ item.dob | moment("utc", "MM/DD/YYYY") }} | Code: {{ item.clientCode || "N/A" }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
            <div v-if="!isAdminOrBilling">
              <v-chip label class="mb-3 ml-5" :color="unitsAvailable > 0 ? 'success' : 'error'" text-color="white">
                <v-avatar>
                  <v-icon small>fa-star</v-icon>
                </v-avatar>
                Units available: {{ unitsAvailable.toLocaleString() }}
              </v-chip>
              <v-chip v-if="week.allowed" label class="mb-3" color="info" text-color="white"> Week allowed: {{ week.allowed }}</v-chip>
              <v-chip label class="mb-3" color="warning" text-color="white"> Week total: {{ week.total }}</v-chip>
            </div>
            <v-form ref="form" autocomplete="off" v-model="validForm">
              <v-subheader>{{ activeDate | moment("LL") }}</v-subheader>
              <v-layout row wrap>
                <v-flex sm12 md4>
                  <v-text-field
                    box
                    required
                    label="Session date"
                    v-model="dateSelected"
                    return-masked-value
                    prepend-icon="fa-calendar"
                    mask="##/##/####"
                    data-vv-name="dateSelected"
                    :rules="errors.collect('dateSelected')"
                    v-validate="'required|date_format:MM/dd/yyyy'"
                    @change="changedDate"
                  />
                </v-flex>
                <v-flex sm12 md4>
                  <v-select box :disabled="loading" label="Pos" v-model="session.pos" required :items="posEnum" prepend-icon="fa-map-marker-alt" :rules="[required]">
                    <template slot="selection" slot-scope="data">
                      <div class="input-group__selections__comma">
                        {{ data.item.text }} &nbsp; <span class="grey--text text--darken-1">({{ data.item.value }})</span>
                      </div>
                    </template>
                    <template slot="item" slot-scope="data">
                      <template>
                        <v-list-tile-avatar>
                          <v-icon>fa-map-marker-alt</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title v-html="data.item.text"></v-list-tile-title>
                          <v-list-tile-sub-title>Code: {{ data.item.value }}</v-list-tile-sub-title>
                        </v-list-tile-content>
                      </template>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex sm4>
                  <v-select box :disabled="loading" label="Service" v-model="service" required :items="services" prepend-icon="fa-map-marker-alt" :rules="[required]" @change="getUnits" />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex sm12 md6>
                  <v-text-field box :disabled="loading" label="From" v-model="timeStart" type="time" required prepend-icon="fa-clock" :rules="[required]"></v-text-field>
                </v-flex>
                <v-flex sm12 md6>
                  <v-text-field box :disabled="loading" label="To" v-model="timeEnd" type="time" required prepend-icon="fa-clock" :rules="[required]"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-alert type="info" :value="durationMins > 0">Units: {{ units }} | Mins: {{ durationMins }} | Hrs: {{ (durationMins / 60).toFixed(1) }}</v-alert>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="loading" flat @click="close">Cancel</v-btn>
            <v-btn :disabled="loading || !validForm" :loading="loading" color="primary" @click="submit">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import masterTableApi from "@/services/api/MasterTablesServices";
import sessionServicesApi from "@/services/api/SessionServices";
import userApi from "@/services/api/UserServices";

export default {
  props: {
    sessionType: {
      type: [Number, String],
      required: false,
      default: 1
    }
  },

  components: {
    SelectUser: () => import(/* webpackChunkName: "SelectUser" */ "@/components/shared/SelectUser")
  },

  data() {
    return {
      activeClientId: 0,
      activeDate: null,
      loading: false,
      validForm: false,
      required: (value) => !!value || "This field is required.",
      posEnum: [],
      timeStart: null,
      timeEnd: null,
      session: {
        pos: null,
        behaviorAnalysisCode: {
          hcpcs: null
        }
      },
      unitsAvailable: 0,
      dateSelected: null,
      week: {
        allowed: null,
        total: null
      },
      userId: null,
      clients: [],
      clientId: null,
      service: null,
      services: []
    };
  },

  computed: {
    // activeClientId() {
    //   return this.$store.getters.activeClientId;
    // },
    // activeDate() {
    //   return this.$store.getters.activeDate;
    // },
    durationMins() {
      return this.$moment(this.timeEnd, "HH:mm").diff(this.$moment(this.timeStart, "HH:mm"), "minutes");
    },
    units() {
      const units = this.durationMins / 15;
      const sum1Unit = units % 1 > 0.5 ? 1 : 0;
      const unitsTruncate = Math.trunc(units) + sum1Unit;
      return unitsTruncate;
    },
    user() {
      return this.$store.getters.user;
    },
    isAdminOrBilling() {
      return this.user.rol2 === "admin" || this.user.rol2 === "billing";
    },
    isLead() {
      return this.user.rol2 === "analyst";
    },
    isAssistant() {
      return this.user.rol2 === "assistant";
    }
  },

  async mounted() {
    if (this.isAdminOrBilling) {
      this.services.push("97153");
      this.services.push("97155");
      this.services.push("97155HN");
      this.service = "97153";
    } else if (this.isLead) {
      this.services.push("97153");
      this.services.push("97155");
      // this.services.push("H2019");
      this.service = "97153";
    } else if (this.isAssistant) {
      this.services.push("97153");
      this.services.push("97155HN");
      this.services.push("97155XP");
      // this.services.push("H2012");
      this.service = "97153";
    } else {
      this.services.push("97153");
      this.services.push("97153XP");
      // this.services.push("H2014");
      this.service = "97153";
    }
    this.activeClientId = this.$store.getters.activeClientId;
    this.activeDate = this.isAdminOrBilling ? new Date() : this.$store.getters.activeDate;
    this.dateSelected = this.$moment(this.activeDate).format("MM/DD/YYYY");
    this.timeStart = this.$moment(this.activeDate).format("HH:00");
    this.posEnum = await masterTableApi.getPosCodes();
    await this.getUnits();
  },

  methods: {
    async getUnits() {
      this.unitsAvailable = await sessionServicesApi.getUnitsAvailable(this.activeClientId, this.$moment(this.activeDate).format("YYYY-MM-DD"), this.service, "");
      await this.getUnitsByWeek();
    },

    close() {
      if (!this.isAdminOrBilling) {
        this.$router.push("/clients/sessions_details");
      } else {
        window.location.reload();
      }
    },

    async submit() {
      try {
        this.loading = true;
        this.session.userId = this.isAdminOrBilling ? this.userId : -1;
        this.session.clientId = this.activeClientId;
        this.session.sessionType = this.sessionType;
        this.session.behaviorAnalysisCode.hcpcs = this.service;
        let dateFixed = this.dateSelected; //.toLocaleDateString('en-US');
        let start = new Date(dateFixed + " " + this.timeStart);
        let end = new Date(dateFixed + " " + this.timeEnd);

        let date7 = new Date(dateFixed + " 07:00:00");
        let date9 = new Date(dateFixed + " 20:59:59");

        if (start < date7 || end > date9) {
          this.$toast.error("You can only create sessions between 7:00 AM and 9:00 PM");
          return;
        }

        // console.log("pase");
        // return;

        this.session.sessionStart = start;
        this.session.sessionEnd = end;
        this.session.totalUnits = this.units;
        this.$confirm(
          `Are you sure do you want to create a new session in:<br>
          <i>Está seguro de que desea crear una nueva sesión en:</i><br><br>
                       Pos: <strong class='red--text pulse'>${this.posEnum.find((s) => s.value == this.session.pos).text.toUpperCase()}</strong><br>
                       Date: ${this.$moment(this.session.sessionStart).format("LL")}<br>
                       Time In: ${this.$moment(this.session.sessionStart).format("LT")}<br>
                       Time Out: ${this.$moment(this.session.sessionEnd).format("LT")}<br>
                       Units: ${this.session.totalUnits}
                       <br><br>
                       <small>Remember your progress note must be clinically linked at:<br>
                       Recuerde su nota debe estar ajustada clínicamente a:<br>
                       <strong class='red--text pulse'>${this.posEnum.find((s) => s.value == this.session.pos).text.toUpperCase()}</strong><br>
                       or your notes will be rejected.<br>
                       o sus notas seran rechazadas.</small>`,
          "red"
        ).then(async (res) => {
          if (res) {
            try {
              await sessionServicesApi.addSession(this.session);
              this.close();
            } catch (error) {
              this.$toast.error(error.message || error);
            }
          }
        });
      } catch (error) {
        this.$toast.error(error.message || error);
      } finally {
        this.loading = false;
      }
    },

    async getUnitsByWeek() {
      try {
        this.loading = true;
        this.week = await sessionServicesApi.getUnitsByWeek(this.activeClientId, this.$moment(this.dateSelected).format("YYYY-MM-DD"), "");
        console.log(this.week);
      } catch (error) {
        console.error(error.message || error);
      } finally {
        this.loading = false;
      }
    },

    async changedDate() {
      await this.getUnitsByWeek();
    },

    async loadUserClients() {
      this.clients = [];
      this.loading = true;
      try {
        console.log(this.userId);
        this.clients = await userApi.loadUserClients(this.userId);
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.loading = false;
      }
    },

    async changedUser() {
      await this.loadUserClients();
    },

    async clientSelected(id) {
      this.$store.commit("SET_ACTIVE_CLIENT", id);
      this.activeClientId = id;
      await this.getUnits();
    }
  }
};
</script>
